import React from 'react'
import { Tooltip, Typography } from "@material-tailwind/react";
import SEO from '../components/SEO';

const About = () => {
  return (
    <>
      <div className="flex justify-center items-center min-h-[calc(100vh-4rem)] pt-0 md:pt-0 lg:pt-6">
        <SEO
          title='About Autism In The Media: Championing Authentic Autistic Representation'
          description='Discover our mission to change the narrative of autism in film and TV. By examining character demographics across decades, we advocate for diversity and challenge common media stereotypes.'
          keywords='about, autism, media, representation, film, TV, advocacy, stereotypes, diversity'
          type='website' />
        <div id="about-section" className="p-8 bg-black text-white max-w-2xl">
          <h1 className="text-4xl mb-6 text-green-400">About</h1>
          
          <p className="mb-4">Welcome to <span className="font-semibold text-yellow-500">Autism In The Media</span>, a site built by <a className="underline text-customRed" href="https://autism-101.com">Autism-101</a> on top of an open source project by <a className="underline text-customRed" href="https://www.youtube.com/@codecommerce/featured">Clint Briley</a>. Accurate representation matters and our mission is to shine a light on the portrayal of autism in film and television, advocating for authentic and diverse depictions that reflect the reality of the autistic community.</p>

          <h2 className="text-2xl md:text-3xl lg:text-3xl text-green-400">The Reality vs The Silver Screen</h2>
          <p className="mb-4">For far too long, Hollywood's lens has narrowly focused on showcasing autistic characters, predominantly as white male savants. While such narratives may be compelling, they are not the whole story. Autism is a spectrum, rich in diversity and experiences. It spans across all races, genders, and backgrounds. By limiting these portrayals, we are not only depriving audiences of a deeper understanding of the autistic experience but also denying the autistic community their rightful representation.</p>

          <h2 className="text-2xl md:text-3xl lg:text-3xl text-green-400">Navigating The Decades</h2>
          <p className="releative mb-4">Our website offers a chronological journey, listing movies and TV shows by decades. Thanks to <a className='underline text-customRed' href="https://themoviedb.org">The Movie DB</a> API, each listing is detailed and complete, providing insights into the representation trends over the years. More importantly, we've incorporated essential markers highlighting films and shows featuring autistic or <span className=' text-yellow-500 cursor-pointer'>autistic-coded</span>
          <Tooltip 
            content={
              <div id="about-tooltip" className="w-80">
                <Typography className="text-purple-400 px-6 pt-4 pb-1 font-medium text-lg">
                  Autistic-Coded
                </Typography>
                <Typography
                  variant="small"
                  color="white"
                  className="px-6 pt-1 pb-4 font-normal opacity-80"
                >
                  Writing or performing a character with several traits common to autistic people 
                  but without confirming it or explicitly discussing it.
                </Typography>
              </div>
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="inline h-5 w-5 cursor-pointer text-purple-400 ml-1 text-center" 
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          </Tooltip> characters. By noting their names, age, gender, and ethnicity, we aim to emphasize the prevailing patterns and inspire a change towards a broader representation.</p>

          <h2 className="text-2xl md:text-3xl lg:text-3xl text-green-400">Celebrating Authenticity</h2>
          <p className="mb-4">While we recognize the areas in need of growth, we also celebrate the strides made in inclusive storytelling. Our platform showcases movies and TV shows that have done an exemplary job in representing autistic individuals. From characters with depth and nuance to narratives that resonate with the lived experiences of many, these are the stories we want more of.</p>

          <h2 className="text-2xl md:text-3xl lg:text-3xl text-green-400">Join The Movement</h2>
          <p className="mb-4">We invite writers, directors, casting agents, and all media creators to dive deep, explore our archives, and understand the breadth of autistic experiences. Let’s collectively redefine what representation looks like, moving away from the clichéd and tokenistic, and embracing the multifaceted realities.</p>

          <p className="mb-4">Because every story matters, it's high time that the authentic stories of the autistic community shine brightly on screens worldwide.</p>

          <hr className="border-gray-600 my-6" />

          <p className="text-center">Join us, advocate with us, and let's create a world where every portrayal counts. Welcome to <span className="font-semibold text-yellow-500">Autism In The Media</span>.</p>
        </div>
      </div>
    </>
  )
}

export default About